import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql, useStaticQuery  } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import GreenCheckMark from '../components/icons/GreenCheckMark'
import IconWrong from '../components/icons/IconWrong'
import QuotationMark from '../components/icons/LiveInCare/QuotationMark';
import Header from "../components/Header";
import Footer from "../components/Footer";
import CqcApproved from '../components/CqcApproved'
import { PostcodeCTA } from '../components/PostcodeCTA/PostcodeCTA'

import "../../scss/main.scss";
import BecomeACarer from "../components/BecomeACarer";
import { Video } from 'gatsby-video'
import LandingPageAnimation from "../components/landing-page-animation";
import { MicroCombo, MiniScore } from "../components/Trustpilot/TrustpilotWidgets";

export default function Home() {
	const { 
		video1Bg,
		video2Bg, 
		centImg, 
		site: { 
			siteMetadata: {
				appConfig: {
					domain: appDomain 
				},
				featureToggles: {
					enableBecomeACarerPageV2,
					enableTimelineAnimation
				}
			} 
		},
		video1,
		video2 
	} = useStaticQuery(
		graphql`
		  query {
			video1Bg: file(relativePath: {eq: "header-img.jpg" }) {
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			video2Bg: file(relativePath: {eq: "we-care-image-2.jpg" }) {
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			centImg: file(relativePath: { eq: "21st-cent-img.png" }) {
				childImageSharp {
				  fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				  }
				}
            }
            site {
                siteMetadata {
                    appConfig {
                        domain
                    }
					featureToggles {
                        enableBecomeACarerPageV2
						enableTimelineAnimation
                    }
                }
            },
			video1: file(relativePath: { eq: "Landingsection4.mp4" }) {
				childVideoFfmpeg {
				  webm: transcode(
					outputOptions: ["-crf 31", "-b:v 0"]
					maxWidth: 900
					maxHeight: 480
					fileExtension: "webm"
					codec: "libvpx-vp9"
				  ) {
					width
					src
					presentationMaxWidth
					presentationMaxHeight
					originalName
					height
					fileExtension
					aspectRatio
				  }
				  mp4: transcode(
					maxWidth: 900
					maxHeight: 480
					fileExtension: "mp4"
					codec: "libx264"
				  ) {
					width
					src
					presentationMaxWidth
					presentationMaxHeight
					originalName
					height
					fileExtension
					aspectRatio
				  }
				}
			  },
			  video2: file(relativePath: { eq: "Landingsection5.mov" }) {
				childVideoFfmpeg {
				  webm: transcode(
					outputOptions: ["-crf 31", "-b:v 0"]
					maxWidth: 900
					maxHeight: 480
					fileExtension: "webm"
					codec: "libvpx-vp9"
				  ) {
					width
					src
					presentationMaxWidth
					presentationMaxHeight
					originalName
					height
					fileExtension
					aspectRatio
				  }
				  mp4: transcode(
					maxWidth: 900
					maxHeight: 480
					fileExtension: "mp4"
					codec: "libx264"
				  ) {
					width
					src
					presentationMaxWidth
					presentationMaxHeight
					originalName
					height
					fileExtension
					aspectRatio
				  }
				}
			  }
        }
		`
	  )

	const centImgBg = centImg.childImageSharp.fluid;
	const video1Background = video1Bg.childImageSharp.fluid;
	const video2Background = video2Bg.childImageSharp.fluid;
	const video1File = video1.childVideoFfmpeg;
	const video2File = video2.childVideoFfmpeg;
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Home</title>
				<meta property="og:title" content="Edyn Care"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content={`${appDomain}/assets/marketing-site/images/open-graph-img.png`}/>
				<meta property="og:url" content="https://www.edyn.care/" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/" />
				<meta name="twitter:title" content="Edyn Care" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			{/*   ========== Nav ==========  */}
			<div className="container main-content">
				<Header />
				<main>
					<div className="grid-container">
						<BackgroundImage className="landing-img" fluid={video1Background}>
							<div className="hero-text-container">
								{/* <TpStars trustScore={5} className="trustpilot-stars"/> */}
								<div class="hero-text">
									<QuotationMark style={{width: 32, marginRight: 6}}/>
									<h1>Responsive, caring and responsible”</h1>
								</div>
                <MiniScore width="70%"/>
							</div>
							<Video
								autoPlay
								loop
								playsInline
								sources={[video1File.webm, video1File.mp4]}
								muted
								
							/>
						</BackgroundImage>
						<div className="landing-context">
							<h1>High quality care in the comfort of your own home.</h1>
							<p className="primary">
								Edyn provides professional carers, support teams and thoughtful
								technology to provide a 24/7 managed care service for your
								family.
							</p>
              
							<Link to="/liveincare/" className="button-link">
								<button className="btn-primary wider">Learn more</button>
							</Link>
						</div>
            
						<PostcodeCTA/>
						<BackgroundImage className="landing-img-mobile" fluid={video1Background}>
							<div className="hero-text-container">
								{/* <StaticImage
									src="../../static/assets/marketing-site/images/trust-pilot-stars.png"
									alt="trustpilot-stars"
									className="trustpilot-stars"
									objectFit="contain"
								/> */}
								<div class="hero-text">
									<QuotationMark style={{width: 32, marginRight: 6}}/>
									<h1>Responsive, caring and responsible”</h1>
								</div>
                <MiniScore classNames={'trustpilot'} width="70%"/>
							</div>
							<Video
								autoPlay
								loop
								playsInline
								sources={[video1File.webm, video1File.mp4]}
								style={{width: "125%"}}
								muted
							/>
						</BackgroundImage>
					</div>

					{/*   ============ WE CARE ====================  */}

					<section className="we-care">
						<StaticImage placeholder="blurred"
							className="img-side we-care__img-left"
							src="../../static/assets/marketing-site/images/we-care-image-1.jpg"
						/>
						
						
						<BackgroundImage className="img-side we-care__img-right" fluid={video2Background}>
							<Video
								autoPlay
								loop
								playsInline
								sources={[video2File.webm, video2File.mp4]}
								muted
							/>
						</BackgroundImage>
						
						<div className="card">
							<div className="img">
								<StaticImage placeholder="blurred"
									src="../../static/assets/marketing-site/images/we-care.png"
									alt="We care image"
								/>
							</div>
							<div className="we-care-content">
								<h1>We truly care for our clients</h1>
								<p className="primary">
									Every Edyn customer has a dedicated care team on hand to help
									throughout the entire process, with updates and online
									summaries that help them understand their loved ones’ care and
									wellness.
								</p>
								<Link to="/approach/">
									<button className="btn-primary">Our approach</button>
								</Link>
							</div>
						</div>
					</section>
					{/*   ============ WE CARE ====================  */}

					{/*   ============ Why live Divider =========== */}
					<section className="why-live-divider">
						<div className="why-live-section">
							<h3>Why live-in care is the best option for your family:</h3>
							<div className="benefits">
								<div className="benefit">
									<GreenCheckMark className="icon-default" />
									<p className="secondary">More affordable than a care home</p>
								</div>
								<div className="benefit">
									<GreenCheckMark className="icon-default" />
									<p className="secondary">
										Remain in the comfort of your home
									</p>
								</div>
								<div className="benefit">
									<GreenCheckMark className="icon-default" />
									<p className="secondary">Better health and wellbeing</p>
								</div>
							</div>
						</div>
					</section>
					{/*   ============ /Why live divider =========== */}

					{/*   ============ Why live Section =========== */}
					<section className="live-in">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
								Each one of our carers is trained in house by our care support team.

								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Stay informed and up to date with our smart online portal.
								</p>
							</div>
						</div>
						<Link to="/approach/" className="button-link">
							<button className="btn-primary">
								Learn more about our process
							</button>
						</Link>
					</section>

					<section className="why-live-section">
						<div className="why-live-quote">
							<div className="why-live-quote-content">
								<div className="wlq-content-container">
									<StaticImage placeholder="tracedSVG"
										src="../../static/assets/marketing-site/images/ian-quote-quotation-mark.png"
										alt="Ian Smith Picture"
										className="quotation-mark-mobile"
										objectFit="contain"
									/>
									<p className="primary ian-quote">
										<span className="quotation-mark-desktop">“</span>
										Care homes used to be the default for individuals who are
										in need of care, but now 99% of those in their later years
										can stay at home with regulated live-in care. Edyn’s service
										is changing care”
									</p>
									<div className="ian-smith">
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/ian_smith.png"
											alt="Ian Smith Picture"
											objectFit="contain"
										/>
										<p>
											Ian Smith
											<br />
											Former Chair of Four Seasons Care Group
										</p>
									</div>
								</div>
							</div>

							<div className="divider-try-live">
								<BecomeACarer
                                    className="compact"
                                    backgroundColor="#F8F8EF"
                                    title="Become an Edyn carer"
                                    description="We’re looking for compassionate, positive and personable individuals to join our team of carers. Vetting and training provided."
                                    actionButtonChildren="Join today"
									link={enableBecomeACarerPageV2 ? "/become-a-carer/" : `${appDomain}/become-a-carer/`}
                                />
							</div>
						</div>
					</section>
					{/*   ============ /Why live Section =========== */}

					{/*   ============ Twenty First Cent Section =========== */}
					<section className="twenty-first-cent">
						<div className="intro">
							<div className="left-block">
								<h2>A care company fit for the 21st century.</h2>
								<p className="primary">
									Alongside our care team we have developed a first-class
									digital experience to help you oversee and stay up to date
									with your care.
								</p>
								<div className="checklist">
									<ul>
										<li>
											<GreenCheckMark className="icon-default" />
											<p className="secondary">Easy to read care plan</p>
										</li>
										<li>
											<GreenCheckMark className="icon-default" />
											<p className="secondary">
												Daily carer notes keeping you informed
											</p>
										</li>
										<li>
											<GreenCheckMark className="icon-default" />
											<p className="secondary">
												Direct contact with your care management team
											</p>
										</li>
										<li>
											<GreenCheckMark className="icon-default" />
											<p className="secondary">
												Track your loved ones health and wellness
											</p>
										</li>
									</ul>
								</div>
								<Link to="/approach/">
									<button className="btn-primary">
										Learn more about our technology
									</button>
								</Link>
							</div>
							<BackgroundImage className="right-block" fluid={centImgBg}>
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cellphone.png" objectFit='contain' className="desktop_view"/>
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cellphone_mobile.png" objectFit='cover' className="mobile_only"/>
							</BackgroundImage>
						</div>
					</section>

					<section className={`timeline ${enableTimelineAnimation && "only-mobile"}`}>
						<div className="things-changed">
							<div className="past">
								<div className="left-block">
									<p className="secondary sub-heading">
										How things have changed
									</p>
									<h2>In the past, seniors had to settle with care homes</h2>
									<div className="checklist">
										<ul>
											<li>
												<IconWrong className="icon-default" />
												<p>No 1:1 care and support</p>
											</li>
											<li>
												<IconWrong className="icon-default" />
												<p>No understanding of wellbeing</p>
											</li>
											<li>
												<IconWrong className="icon-default" />
												<p>Limited family interaction</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="time-scroller desktop">
									<div className="background-shadow-fade">
										<h1>1990</h1>
									</div>
								</div>
								<div className="right-block">
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/img-1990.png"
										alt=""
										className="desktop_view"
									/>
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/img-1990-mobile.png"
										alt=""
										className="mobile_view"
									/>
								</div>
							</div>
							{/*   2021  */}
							<div className="today">
								<div className="left-block">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/today.png" className="desktop_view"/>
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/today-mobile.png" className="mobile_view"/>
								</div>
								<div className="time-scroller desktop">
									<div className="background-shadow-fade">
										<h1>2021</h1>
									</div>
								</div>
								<div className="right-block">
									<h2>
										Now, with regulated live-in care you can stay in the comfort
										of your home.
									</h2>
									<div className="checklist">
										<ul>
											<li>
												<GreenCheckMark className="icon-default" />
												<p className="secondary">Comfort of your own home</p>
											</li>
											<li>
												<GreenCheckMark className="icon-default" />
												<p className="secondary">Dedicated 1:1 care team</p>
											</li>
											<li>
												<GreenCheckMark className="icon-default" />
												<p className="secondary">
													Focus on health and wellness
												</p>
											</li>
											<li>
												<GreenCheckMark className="icon-default" />
												<p className="secondary">Family can stay anytime</p>
											</li>
										</ul>
									</div>
									<Link to="/cta/">
										<button className="btn-primary">
											Get started with Edyn
										</button>
									</Link>
								</div>
							</div>
						</div>
					</section>

					{enableTimelineAnimation && <LandingPageAnimation className="timeline--desktop"/>}
					{/*   Trust pilot embed goes here  */}
          			<MicroCombo />
					<CqcApproved />
				</main>{" "}

    			<Footer />

			</div>
			{/*  end of container */}

		</>
	);
}
