import React, { useRef, useState, useLayoutEffect } from "react";
import "../../scss/main.scss";
import IconWrong from './icons/IconWrong'
import GreenCheckMark from './icons/GreenCheckMark'
import { StaticImage } from "gatsby-plugin-image"
import { LinkWithUTM as Link } from './LinkWithUTM'


const easeInOut = x => {
    if (x < 1) return 3 * Math.pow(x, 2) - 2 * Math.pow(x, 3);
    return 1
}

const genEasyInOut = (x1, x2, y1, y2) => {
    const duration = x2 - x1;
    const travel = y2 - y1
    return x => {
        if (x < x1 || x > x2) throw new Error('Progress parameter out of bounds')
        return y1 + easeInOut((x - x1) / duration) * travel
    }
}

const sizes = {
    TABLET: 768,
    IPAD_PRO: 1024,
    MACBOOK: 1152,
    DESKTOP: 1440
}


const useAnimationFrame = (callback) => {
    const callbackRef = useRef(callback);
    const frameRef = useRef();
    const timerRef = useRef();

    useLayoutEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useLayoutEffect(() => {
        const loop = (time) => {
            frameRef.current = requestAnimationFrame(loop);

            let dt = 0;
            if (timerRef.current !== undefined && timerRef.current !== null)
                dt = time - timerRef.current;

            const callback = callbackRef.current;
            callback(dt / 1000);

            timerRef.current = time;
        };

        frameRef.current = requestAnimationFrame(loop);
        return () => cancelAnimationFrame(frameRef.current);
    }, []);
};

const getInnerWindowState = (outerContainer, innerContainer, scrollTop) => {
    const scrollBottom = window.scrollY + innerContainer.height;
    const outerContainerTop = outerContainer.y + scrollTop
    const outerContainerBottom = outerContainerTop + outerContainer.height;

    if (scrollTop < outerContainerTop) return 'FIXED_TO_TOP'
    if (scrollBottom > outerContainerBottom) return 'FIXED_TO_BOTTOM'
    return 'FIXED_TO_VIEWPORT'
}
const image1Opacity = x => {
    if (x < 0.4) return easeInOut(x*2.5)
    return 1
}

const image2Opacity = x => {
    if (x < (1/2.5)) return easeInOut(x * 2.5)
    return 1
}

const image1PosY = x => {
    if (x < 0.5) return genEasyInOut(0, 0.5, 0.4, 0.2)(x);
    if (x < 0.6) return 0.2;
    return genEasyInOut(0.6, 1, 0.2, -0.7)(x);
}

const image2PosY = (x, offset) => {
    if (x < 0.5) return genEasyInOut(0, 0.5, 1, 0.2 + offset)(x);
    if (x < 0.6) return 0.2 + offset;
    return genEasyInOut(0.6, 1, 0.2 + offset, -0.35)(x);
}

const inThePastTextMarginTop = x => {
    if (x < 0.5) return genEasyInOut(0, 0.5, 0.2, 0)(x);
}


const image3PosY = x => {
    if (x < 0.55) return 0;
    return genEasyInOut(0.55, 1, 0, 0.85)(x);
}

const image4PosY = (x, offset) => {
    if (x < 0.55) return 0;
    return genEasyInOut(0.55, 1, 0, 0.85 - offset)(x);
}

const containerNowOpacity = x => {
    return Math.max(0, 2.5 * x - 1.5)
}

const containerNowPosY = x => {
    if (x < 0.7) return 0;
    return genEasyInOut(0.7, 1, 0, 0.8)(x);
}

const progressToDate = x => {
    if (x < 0.6) return 1990;
    const dateDifference = new Date().getFullYear() - 1990;
    return 1990 + Math.ceil(genEasyInOut(0.6, 1, 0, 1)(x) * dateDifference);
}
const yearOpacity = x => {
    if (x < 0.4) return genEasyInOut(0, 0.4, 0, 1)(x);
    return 1
}
const yearPosY = x => {
    if (x < 0.4) return genEasyInOut(0, 0.4, 0.8, 0.5)(x);
    return 0.5;
}


const LandingPageAnimation = ({ className, ...props }) => {
    const outerContainerRef = useRef()
    const innerContainerRef = useRef()
    const img2Ref = useRef()


    const [results, setResults] = useState({})

    useAnimationFrame(() => {

        const windowHeight = window.innerHeight || 0;
        const windowWidth = window.innerWidth || 0;
        const outerContainer = outerContainerRef.current.getBoundingClientRect();
        const innerContainer = innerContainerRef.current.getBoundingClientRect();
        const innerContainerHeight = innerContainer.height
        const innerContainerWidth = innerContainer.width
        const scrollTop = window.scrollY;
        const outerContainerTop = outerContainer.y + scrollTop
        const outerContainerBottom = outerContainerTop + outerContainer.height;
        const outerContainerScroll = scrollTop - outerContainerTop;
        const progressEarlyStart = 500;
        const progress = Math.max(0, (outerContainerScroll + progressEarlyStart) / (outerContainer.height - innerContainer.height + progressEarlyStart));


        setResults({
            windowHeight,
            windowWidth,
            scrollTop,
            innerContainerHeight,
            innerContainerWidth,
            outerContainerTop,
            outerContainerBottom,
            progress: progress > 1 ? 1 : progress,
            innerWindowState: getInnerWindowState(outerContainer, innerContainer, window.scrollY),
            image2OffsetRatio: 300 / innerContainerHeight,
            image4OffsetRatio: 300 / innerContainerHeight
        })
    })

    return (
        <>
            <section className={className} style={{
                height: '400vh',
                position: 'relative',
            }} ref={outerContainerRef}>
                <div ref={innerContainerRef} style={
                    Object.assign({
                        //border: '5px solid blue',
                        boxSizing: 'border-box',
                        maxWidth: 1440,
                        margin: "auto"
                    }, results.innerWindowState === 'FIXED_TO_VIEWPORT' ?
                        {
                            position: "fixed",
                            top: "0",
                            left: "0",
                            bottom: '0',
                            right: '0'
                        } : results.innerWindowState === 'FIXED_TO_TOP' ? {
                            width: '100%',
                            height: '100vh'
                        } : {
                            width: '100%',
                            height: '100vh',
                            bottom: 0,
                            position: 'absolute'
                        })
                }>
                    {/*=================YEAR TO DISPLAY================*/}
                    <div className="scroll-animation__timeline"
                        style={{
                            willChange: "top, opacity",
                            opacity: yearOpacity(results.progress),
                            position: "absolute",
                            top: results.windowWidth >= sizes.IPAD_PRO ? (results.innerContainerHeight * yearPosY(results.progress)) : 20,
                            
                        }}
                    >
                        <div>{progressToDate(results.progress)}</div>
                    </div>

                    {/* ================= IN THE PAST =================*/}
                    <div className="scroll-animation__left-container-past" style={{
                        willChange: "opacity, top, left",
                        position: "absolute",
                        opacity: image1Opacity(results.progress),
                        top: results.innerContainerHeight * image1PosY(results.progress),
                        left: results.windowWidth <= sizes.TABLET ? 0.1 * results.innerContainerWidth
                            : results.windowWidth <= sizes.MACBOOK ? 0.075 * results.innerContainerWidth
                            : 0.11 * results.innerContainerWidth,
                        transition: 'top 0.1s',
                    }}>
                        <p className="scroll-animation__left-container-past-how-things">How things have changed</p>
                        <h3 className="scroll-animation__left-container-past-in-the-past">
                            In the past seniors had to settle with care homes
                        </h3>
                        <div className="scroll-animation__left-container-past-checklist" style={{
                            willChange: "transform",
                            transform: `translateY(${results.innerContainerHeight * inThePastTextMarginTop(results.progress)}%)`
                        }}>
                            <div className="scroll-animation__left-container-past-checklist-element">
                                <IconWrong />
                                No 1:1 care and support
                            </div>
                            <div className="scroll-animation__left-container-past-checklist-element">
                                <IconWrong />
                                No undersanding of wellbeing
                            </div>
                            <div className="scroll-animation__left-container-past-checklist-element">
                                <IconWrong />
                                Limited family interaction
                            </div>
                        </div>
                    </div>
                    <StaticImage
                        style={{
                            willChange: "opacity, top, right",
                            width: 300,
                            height: 400,
                            opacity: image1Opacity(results.progress),
                            position: 'absolute',
                            transition: 'top 0.1s',
                            top: results.innerContainerHeight * image1PosY(results.progress),
                            left: "auto",
                            right: 0.1 * results.innerContainerWidth,
                            borderRadius: 10,
                        }}
                        src="../../static/assets/marketing-site/images/animation-past-1.png"
                    />
                    <StaticImage
                        style={{
                            willChange: "opacity, top, right",
                            width: 214,
                            height: 222,
                            opacity: image2Opacity(results.progress),
                            position: 'absolute',
                            transition: 'top 0.1s',
                            top: results.innerContainerHeight * image2PosY(results.progress, results.image2OffsetRatio),
                            left: "auto",
                            right: 0.05 * results.innerContainerWidth,
                        }}
                        ref={img2Ref}
                        src="../../static/assets/marketing-site/images/animation-past-2.png"
                    />

                    {/* ===================== NOW =====================*/}
                    <StaticImage
                        className="scroll-animation__left-container-img1"
                        src="../../static/assets/marketing-site/images/animation-now-1.png"
                        width={303}
                        height={369}
                        style={{
                            willChange: "top left",
                            top: results.innerContainerHeight - (results.innerContainerHeight * image3PosY(results.progress)),
                            position: "absolute",
                            left: 0.05 * results.innerContainerWidth,
                            transition: 'top 0.1s',
                        }}
                    />
                    <StaticImage
                        width={180}
                        height={280}
                        className="scroll-animation__left-container-img2"
                        src="../../static/assets/marketing-site/images/animation-now-2.png"
                        style={{
                            willChange: "top, left",
                            top: results.innerContainerHeight - (results.innerContainerHeight * image4PosY(results.progress, results.image4OffsetRatio)),
                            position: "absolute",
                            left: 0.2 * results.innerContainerWidth,
                            transition: 'top 0.1s',
                        }}
                    />
                    <div className="scroll-animation__right-container-now"
                        style={{
                            willChange: "right, top",
                            position: "absolute",
                            right: 0.05 * results.innerContainerWidth,
                            transition: 'top 0.1s',
                            top: results.innerContainerHeight - (results.innerContainerHeight * containerNowPosY(results.progress))
                        }}
                    >
                        <h3 className="scroll-animation__right-container-now-title" style={{
                            willChange: "transform",
                            transform: `translateY(-${(1 - results.scrollProgressRatio)* 800}px)`,
                        }}>Now, with regulated live-in care you can stay in the comfort of your home.</h3>
                        <div className="scroll-animation__right-container-now-checklist" 
                            style={{
                                willChange: "transform, opacity",
                                transform: `translateY(-${(1 - results.scrollProgressRatio)* 900}px)`,
                                opacity: containerNowOpacity(results.progress),
                            }}
                        >
                            <div className="scroll-animation__right-container-now-checklist-element">
                                <GreenCheckMark />
                                Comfort of your own home
                            </div>
                            <div className="scroll-animation__right-container-now-checklist-element">
                                <GreenCheckMark />
                                Dedicated 1:1 care team
                            </div>
                            <div className="scroll-animation__right-container-now-checklist-element">
                                <GreenCheckMark />
                                Focus on health and wellness
                            </div>
                            <div className="scroll-animation__right-container-now-checklist-element">
                                <GreenCheckMark />
                                Family can stay anytime
                            </div>
                        </div>
                        <Link to="/cta/">
                            <button className="btn-primary" style={{
                                willChange: "box-shadow",
                                boxShadow: results.progress < 0.95 ? '0px 0px 0px 0px rgba(0,0,0,0.35)': 'rgba(0, 0, 0, 0.35) 7px 8px 19px 0px',
                                transition: 'box-shadow 1s',
                            }}>Get started with Edyn</button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}


export default LandingPageAnimation