import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

function CqcApproved(props){
    return (
        <section>
            <div className="tp-divider external">
                <div className="tp-left">
                    <StaticImage className="cqc-approved" placeholder="blurred" src="../../static/assets/marketing-site/images/cqc-approved.png" objectFit="contain" />
                    <p className="secondary">
                        Edyn Care is approved and regulated by the Care Quality
                        commission
                    </p>
                </div>
                <div className="press">
                    <StaticImage placeholder="blurred"
                        className="tp-img"
                        src="../../static/assets/marketing-site/images/thegaurdian.png"
                        alt="the Gaurdian"
                        objectFit="contain"
                    />
                    <StaticImage placeholder="blurred"
                        className="tp-img"
                        src="../../static/assets/marketing-site/images/thetimes.png"
                        alt="the times"
                        objectFit="contain"
                    />
                    <StaticImage placeholder="blurred"
                        className="tp-img"
                        src="../../static/assets/marketing-site/images/theeveningstandard.png"
                        alt="evening standard"
                        objectFit="contain"
                    />
                </div>
            </div>
        </section>
    )
}

export default CqcApproved