import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import './becomeacarer.scss';
import {LinkWithUTM as Link} from '../LinkWithUTM'

const BecomeACarer = (props) => {
    const mailOrPhoneLink = props.link.startsWith('tel:') || props.link.startsWith('mailto:')
    return(
        <section className={props.className}>
            <div className="become-a-carer" style={{backgroundColor: props.backgroundColor}}>
                <div className="become-carer-left">
                    <StaticImage 
                        src="../../../static/assets/marketing-site/images/bac-pattern.png"
                        placeholder="tracedSVG"
                        className="become-a-carer-pattern"
                    />
                    <StaticImage 
                        src="../../../static/assets/marketing-site/images/bac-pattern--mobile.png"
                        placeholder="tracedSVG"
                        className="become-a-carer-pattern--mobile"
                    />
                    <StaticImage placeholder="blurred"
                        className="become-carer-people"
                        src="../../../static/assets/marketing-site/images/become-carer-people.png"
                        alt="Carer Image"
                    />
                </div>
                <div className="become-carer-right">
                    <h3 className="heading">{props.title}</h3>
                    <h3 className="heading_mobile">{props.mobileTitle}</h3>
                    <p className="primary middle">
                        {props.description}
                    </p>
                    {mailOrPhoneLink ?
                        (<a href={props.link} className="contact-number">
                            <p className="primary contact-detail">
                                {props.actionButtonChildren}
                            </p>
                        </a>)
                    :
                        (<Link to={props.link} className="contact-number">
                            <p className="primary contact-detail">
                                {props.actionButtonChildren}
                            </p>
                        </Link>)
                    }
                </div>
            </div>
        </section>
    )
}
 
export default BecomeACarer;