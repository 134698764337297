import React, {useState, useCallback} from 'react'
import { navigate } from 'gatsby'
import SearchBtn from '../icons/SearchBtn'
import { StaticImage } from 'gatsby-plugin-image'
import './postcodecta.scss'

/* global _paq */


const PostcodeCTAInput = () => {
    const [postcodeInput, setPostcodeInput] = useState('')
    const searchPostcode = useCallback(() => {
        if (typeof _paq !== 'undefined') {
            _paq && _paq.push(['trackEvent', 'PostcodeCta', 'Submit', postcodeInput]);

        }
        const postcode=encodeURIComponent(postcodeInput)
        const search = `${document.location.search ? `${document.location.search}&` : '?'}postcode=${postcode}`
        navigate(`/cta/${search}`)
    }, [postcodeInput])



    return (
        <div className="input-wrapper">
            <input
                className=""
                type="text"
                id="postalcode"
                name="postcode"
                placeholder="Enter your postcode"
                value={postcodeInput}
                onChange={e => {
                    setPostcodeInput(e.target.value)
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') searchPostcode()
                }}
            />
            <div className="search-btn-wrapper" onClick={()=>searchPostcode()}>
                <div className="search-btn">
                    <SearchBtn/>
                </div>
            </div>
        </div>

    )
}

const PostcodeCTA = ({className = ""}) => {
    return(
        <div className={`postcode-cta ${className}`}>
            <div className="postcode-top">
                <div className="postcode-form">
                    <p className="title">Start your care journey today:</p>
                    <PostcodeCTAInput />
                </div>
            </div>
            <div className="postcode-footer">
                <div className="call-us">
                    <StaticImage placeholder="blurred"
                        src="../../../static/assets/marketing-site/images/postcode-img.png"
                        alt="advisors-img"
                        objectFit="contain"
                    />
                    <div className="call-us-details">
                        <div className="secondary">Or call us today on:</div>
                        <a href="tel:020 3970 9900" className="number">020 3970 9900</a>
                    </div>
                </div>
            </div>
        </div>				
    )
}

export { PostcodeCTA, PostcodeCTAInput };