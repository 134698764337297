import React from 'react';

const MicroCombo = ({classNames}) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const microRef = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(microRef.current, true);
    }
  }, []);
  return (
    <div
      ref={microRef}
      class={`trustpilot-widget ${classNames}`} data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5c5455fdfdd26f0001066878" data-style-height="20px" data-style-width="100%" data-theme="light">
      <a href="https://uk.trustpilot.com/review/edyn.care" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  );
};

const MiniScore = ({width = "100%", classNames}) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const miniRef = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(miniRef.current, true);
    }
  }, []);
  return (
    <div
      ref={miniRef}
      class={`trustpilot-widget ${classNames}`} data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5c5455fdfdd26f0001066878" data-style-height="150px" data-style-width={width} data-theme="dark">
      <a href="https://uk.trustpilot.com/review/edyn.care" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  );
};

export { MicroCombo, MiniScore }